// src/components/Tracking.tsx
import React, { useEffect } from 'react';
import { UserPreferenceProvider } from "../contexts/UserPreferencesContext";
import { DevicesProvider } from "../contexts/GpsDeviceContext";
import { StatusCountsProvider } from "../contexts/StatusCountContext";
import { FilterProvider } from "../contexts/FilterDevicesContext";
import { WebSocketProvider } from "../contexts/WebsocketContext";
import { TourProvider, useTour } from "../contexts/TourContext";
import DevicesList from "./devices/DevicesList";
import TopBar from "./topbar/TopBar";
import MapComponent from "./map/Map";
import { NotificationProvider } from "../contexts/NotificationContext";
import Notifications from "./notifications/Notifications";
import { Tour, ConfigProvider } from "antd";
import type { TourProps } from 'antd';
import esES from 'antd/locale/es_ES';
import { APIProvider } from "@vis.gl/react-google-maps";
import AlertModal from './alert_modal/AlertModal';
import { AlertProvider } from '../contexts/AlertContext';
import { SpecialMessagesProvider } from '../contexts/SpecialMessagesContext';
import SpecialMessagesModal from './special_messages_modal/SpecialMessageModal';

import { updateShowTour } from "./../features/users/User";

esES.Tour = {
  Next: 'Siguiente',
  Previous: 'Anterior',
  Finish: 'Finalizar',
};


const Content = ({showTour, cluster, mobile_notification, date_format, time_zone, odometer_unit} : {showTour: boolean, cluster: boolean, mobile_notification: boolean, date_format: string, time_zone: string, odometer_unit: string}) => {
  const { ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, setOpen, open } = useTour();

  useEffect(() => {
    if (showTour) {
      setTimeout(() => setOpen(true), 800); 
    }
  }, [showTour, setOpen]);

  const steps: TourProps['steps'] = [
    {
      title: "Filtro por estado de movimiento",
      description: "Filtra por estado de movimiento el listado de unidades",
      target: () => ref1.current
    },
    {
      title: "Filtro por grupos",
      description: "Filtra por grupos el listado de unidades",
      target: () => ref2.current,
    },
    {
      title: "Filtrar por estado",  
      description: "Filtra por estado el listado de unidades",
      target: () => ref3.current
    },
    {
      title: "Zona de seguimiento",
      description: "Ubica la dirección en tiempo real y coordenadas",
      target: () => ref4.current
    },
    {
      title: "Comandos",
      description: "Activa paro de motor y Poleo (actualización de coordenadas en tiempo real)",
      target: () => ref5.current
    },
    {
      title: "Huella de unidad",
      description: "Activa el recorrido en tiempo real",
      target: () => ref6.current
    },
    {
      title: "Recorrido",
      description: "Muestra el recorrido del día actual",
      target: () => ref7.current
    },
    {
      title: "íconos para estatus de la unidad",
      description: <>
        <ol className='list-numbers'>
            <li>Batería GPS</li>
            <li>Intensidad de señal</li>
            <li>Última actualización</li>
            <li>Velocidad actual</li>
        </ol>
      </>,
      target: () => ref8.current
    },
    {
      title: "Herramientas",
      description: "Encontrarás link de seguimiento, geocercas y buscar ubicaciones en el mapa",
      target: () => ref9.current
    },
    {
      title: "Capas visuales",
      description: "Puedes habilitar lo que requieras visualizar en el mapa",
      target: () => ref10.current
    },
    {
      title: "Menu General",
      description: "Acceso al administrador de usuarios, reportes, configuración y cerrar sesión",
      target: () => ref11.current
    },
  ];

  const handleFinish = () => {
    updateShowTour({show_tour: false});
  };
  

  return (
    <div className="app-container">
      <TopBar />
      <DevicesList />
      <MapComponent />
      <Notifications />
      {showTour && <Tour open={open} onClose={() => setOpen(false)} steps={steps} className="tour" onFinish={handleFinish} />}
      <AlertModal />
      <SpecialMessagesModal />
    </div>
  );
}
const Tracking: React.FC<any> = (props) => {

  const [preferences, setPreferences] = React.useState(props);

  useEffect(() => {
    setPreferences(props);
  }, [props]);

  // console.log("MAPKEY", process.env.REACT_APP_GOOGLE_MAPS_API_KEY); 
  
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <AlertProvider>
        <SpecialMessagesProvider>
          <UserPreferenceProvider initialPreferences={preferences}>
            <NotificationProvider>
              <DevicesProvider initialGroups={preferences.groups}>
                <StatusCountsProvider>
                  <FilterProvider>
                    <WebSocketProvider>
                      <TourProvider>
                        <ConfigProvider locale={esES}>
                          <Content showTour={preferences.user_preferences.show_tour} cluster={preferences.user_preferences.cluster} mobile_notification={preferences.user_preferences.mobile_notification} date_format={preferences.user_preferences.date_format} time_zone={preferences.user_preferences.time_zone} odometer_unit={preferences.user_preferences.odometer_unit} />
                        </ConfigProvider>
                      </TourProvider>
                    </WebSocketProvider>
                  </FilterProvider>
                </StatusCountsProvider>
              </DevicesProvider>
            </NotificationProvider>
          </UserPreferenceProvider>
        </SpecialMessagesProvider>
      </AlertProvider>
    </APIProvider>
  );
};

export default Tracking;
