// src/contexts/TourContext.tsx
import React, { createContext, useContext, useRef, useState, ReactNode, MutableRefObject, useEffect } from 'react';

interface TourContextType {
  ref1: MutableRefObject<HTMLDivElement | null>;
  ref2: MutableRefObject<HTMLDivElement | null>;
  ref3: MutableRefObject<HTMLDivElement | null>;
  ref4: MutableRefObject< HTMLButtonElement | null>;
  ref5: MutableRefObject< HTMLButtonElement | null>;
  ref6: MutableRefObject< HTMLButtonElement | null>;
  ref7: MutableRefObject< HTMLButtonElement | null>;
  ref8: MutableRefObject<HTMLDivElement | null>;
  ref9: MutableRefObject< HTMLDivElement | null>;
  ref10: MutableRefObject< HTMLDivElement | null>;
  ref11: MutableRefObject<HTMLDivElement | null>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TourContext = createContext<TourContextType | undefined>(undefined);

export const TourProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const ref1 = useRef<HTMLDivElement | null>(null);
    const ref2 = useRef<HTMLDivElement | null>(null);
    const ref3 = useRef<HTMLDivElement | null>(null);
    const ref4 = useRef<HTMLButtonElement | null>(null);
    const ref5 = useRef<HTMLButtonElement | null>(null);
    const ref6 = useRef<HTMLButtonElement | null>(null);
    const ref7 = useRef<HTMLButtonElement | null>(null);
    const ref8 = useRef<HTMLDivElement | null>(null);
    const ref9 = useRef<HTMLDivElement | null>(null);
    const ref10 = useRef<HTMLDivElement | null>(null);
    const ref11 = useRef<HTMLDivElement | null>(null);
    
    const [open, setOpen] = useState(false);


    return (
        <TourContext.Provider value={{ ref1, ref2, ref3,ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, open, setOpen }}>
        {children}
        </TourContext.Provider>
    );
};

export const useTour = (): TourContextType => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};
