import "rsuite/dist/rsuite.min.css";
import React, { useEffect } from "react";
import { Dropdown, Whisper, Popover, Button, Input, InputGroup, Toggle, Checkbox, Avatar, Badge } from 'rsuite';
import Icon from '../Icon';
import { useUserPreferences } from "../../contexts/UserPreferencesContext";
import Item from "./Item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faDrawPolygon, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from "../../contexts/NotificationContext";
import { useTour } from '../../contexts/TourContext';
import { useFilters } from "../../contexts/FilterDevicesContext";
import { storage } from "../../services/storage";
import useCheckboxGroup from '../../hooks/useCheckboxGroup';
import CheckboxGroupComponent from "../checkboxtree/CheckboxGroupComponent";
import { Form, Modal, Select, Upload, Row, Col } from 'antd';
import ExternalLinkModal from "./ExternalLinkModal";
import { APP_URL } from "../../utils/constants";
import { updatePreferences } from "../../features/users/User";
import { useAlert } from "../../contexts/AlertContext";

// const toolsMapStyles = [
//     { label: "Dark", key: "dark"},
//     { label: "Standard", key: "standard"},
//     { label: "Silver", key: "silver"},
//     { label: "Retro", key: "retro"},
//     { label: "Night", key: "night" },
//     { label: "Aubergine", key: "aubergine" },
// ];

const geofences = [
    {
        label: 'Nombres',
        value: 'geofences_name',
    },
    {
        label: 'Lineal',
        value: 'linear'
    },
    {
        label: 'Poligonal',
        value: 'polygonal'
    },
    {
        label: 'Circular',
        value: 'circle'
    },
];

const points = [
    {
        label: 'Nombres',
        value: 'poi_name',
    },
];

const VizualizationModal = ({ open, handleOpen }) => {

    const { 
        show_information,
        setShowInformation,
        show_drivers,
        setShowDrivers,
        show_actions,
        setShowActions,
        show_trailers,
        setShowTrailers,
        show_events,
        setShowEvents,
        show_device_status,
        setShowDeviceStatus,
        show_device_properties,
        setShowDeviceProperties,
        show_quick_view,
        setShowQuickView,
        show_foot_print_view,
        setShowFootPrintView,
        show_individual_view,
        setShowIndividualView,

    } = useUserPreferences();

    return(
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="filters" size="18" />
                    </div>
                    <span className="modal-title">Datos de visualizacion</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            footer={null}
            onCancel={handleOpen}
        >
            <div className="modal-body">
                <span className="title">Generales</span>
                <Checkbox onChange={(value, checked) => setShowInformation(checked)} checked={show_information} >Informacion</Checkbox>
                <Checkbox onChange={(value, checked) => setShowDrivers(checked)} checked={show_drivers} >Conductores</Checkbox>
                <Checkbox onChange={(value, checked) => setShowActions(checked)} checked={show_actions} >Acciones</Checkbox>
                <Checkbox onChange={(value, checked) => setShowTrailers(checked)} checked={show_trailers} >Remolques</Checkbox>
                <Checkbox onChange={(value, checked) => setShowEvents(checked)} checked={show_events} >Eventos</Checkbox>
                <Checkbox onChange={(value, checked) => setShowDeviceStatus(checked)} checked={show_device_status} >Estado de dispositivo</Checkbox>
                <Checkbox onChange={(value, checked) => setShowDeviceProperties(checked)} checked={show_device_properties} >Propiedades de dispositivo</Checkbox>
                <Checkbox onChange={(value, checked) => setShowFootPrintView(checked)} checked={show_foot_print_view} >Huella de unidad</Checkbox>
                <Checkbox onChange={(value, checked) => setShowQuickView(checked)} checked={show_quick_view} >Recorrido rapido</Checkbox>
                <Checkbox onChange={(value, checked) => setShowIndividualView(checked)} checked={show_individual_view} >Vista individual</Checkbox>
            </div>
            <div className="modal-footer">
                <Button onClick={handleOpen} className="text-bold" appearance="primary">Guardar</Button>
            </div>
        </Modal>
        
    )
}

const UserPreferencesModal = ({ open, handleOpen }) => {
    const { preferences } = useUserPreferences();
    const [form] = Form.useForm();  // Hook de Ant Design para manejar el formulario
    const [mobile_notification, setMobileNotification] = React.useState(preferences.user_preferences.mobile_notification ?? false);
    const [show_tour, setShowTour] = React.useState(preferences.user_preferences.show_tour ?? false);
    const [cluster, setCluster] = React.useState(preferences.user_preferences.cluster ?? false);
    const [submitting, setSubmitting] = React.useState(false);
    const { showAlert } = useAlert();
  
    // Inicializa el formulario con los valores actuales
    useEffect(() => {
        console.log({form})
        if (form){
            form.setFieldsValue({
              odometer_unit: preferences.user_preferences.units.odometer,
              time_zone: preferences.user_preferences.timezone,
              date_format: preferences.user_preferences.units.date,
            });
        }
    }, [preferences, form]);
  
    const handleFinish = (values) => {
        setSubmitting(true);
        const data = {
            odometer_unit: values.odometer_unit,
            time_zone: values.time_zone,
            date_format: values.date_format,
            mobile_notification,
            cluster,
            show_tour: show_tour,
        };

        updatePreferences({settings: data}).then(response => {
            if(response.success){
                window.location.reload();
            }else{
                showAlert("error", "Error", "Ocurrio un error al guardar las preferencias, intenta de nuevo");
                setSubmitting(false);
            }
        }).catch(error => {
            showAlert("error", "Error", "Ocurrio un error al guardar las preferencias, intenta de nuevo, error: " + error);
            setSubmitting(false);
        })
    };
  
    return (
      <Modal
        title={
            <div className="flex-row align-center">
                <div className="modal-icon-title">
                    <Icon icon="edit-user" size="18" />
                </div>
                <span className="modal-title">Preferencias de usuario</span>
            </div>
        }
        width={721}
        centered
        maskClosable={false}
        open={open}
        footer={null}
        onCancel={handleOpen}
      >
        <div className="modal-body pb-0">
          <Form
            layout="vertical"
            form={form}
            onFinish={handleFinish}
          >
            <span className="title">General</span>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Unidad de medida"
                  name="odometer_unit"
                >
                  <Select>
                    <Select.Option value="km">Kilometros (km)</Select.Option>
                    <Select.Option value="mi">Millas (mi)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Zona horaria"
                  name="time_zone"
                >
                  <Select showSearch>
                    {
                      preferences.timezones.map((timezone, index) => (
                        <Select.Option key={index} value={timezone.value}>
                          {timezone.label}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Formato de fecha"
                  name="date_format"
                >
                  <Select>
                    <Select.Option value="lat">DD/MM/YY</Select.Option>
                    <Select.Option value="usa">MM/DD/YY</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="horizontal-divider"></div>
            <span className="title">Software</span>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item>
                    <Checkbox
                        onChange={(value, checked) => setMobileNotification(checked)}
                        checked={mobile_notification}
                    >Notificaciones en la app</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item>
                    <Checkbox
                        onChange={(value, checked) => setCluster(checked)}
                        checked={cluster}
                    >Agrupar unidades</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item>
                    <Checkbox
                        onChange={(value, checked) => setShowTour(checked)}
                        checked={show_tour}
                    >Mostrar tour</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <div className="horizontal-divider"></div>
            <div className="flex-row justify-between">
              <Button appearance="ghost" className="cancel mr-1" onClick={handleOpen} disabled={submitting}>Regresar</Button>
              <Button appearance="primary" className="save" type="submit" loading={submitting} disabled={submitting}>Guardar</Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  };

const TopBar = () => {
    const { 
        preferences, 
        setShowTracking, 
        setShowDeviceNames, 
        setShowTraffic,
        show_device_names,
        show_traffic,
        setPoiNames,
        setShowCircularGeofences,
        setGeofencesNames,
        setShowPolygonalGeofences,
        setShowLinearGeofences,
        setMapStyle,
        map_style,
        setMeasuringToolActive,
        measuring_tool_active,
        search_places_active,
        setSearchPlacesActive,
        new_route_active,
        setNewRouteActive,
        setShowNewCircleGeofence,
        show_new_circle_geofence,
        setShowNewPolygonalGeofence,
        show_new_polygonal_geofence,
        setNewPoint,
        new_point,
        handleToolActivation,
    } = useUserPreferences();
    const { setShowNotifications, show_notifications, unred_notifications } = useNotifications();
    const { handleSearch } = useFilters();
    const [show_vizualization_data, setShowVisualizationData] = React.useState(false);
    const [show_external_link, setShowExternalLink] = React.useState(false);
    const [show_user_preferences, setShowUserPreferences] = React.useState(false);

    const initialSelectedGeofences = [];
    const initialSelectedPoints = [];
    
    const { ref9, ref10, ref11 } = useTour();

    const { value: geofenceValue, handleChange: handleGeofenceChange, handleCheckAll: handleGeofenceCheckAll } = useCheckboxGroup(geofences, initialSelectedGeofences);
    const { value: pointsValue, handleChange: handlePointsChange, handleCheckAll: handlePointsCheckAll } = useCheckboxGroup(points, initialSelectedPoints);

    const menuItems = [
        { label: 'Administracion', key: 'administration', icon: <Icon icon="admin" size={12} />, href:"/admin", type: 'link' },
        { label: "Reportes", key: "reports", icon: <Icon icon="icon-data" size={12} /> , href:"https://reports.mobility-go.mx", type: 'link' },
        { label: "Preferencias de usuario", key: "user_preferences", icon: <Icon icon="edit-user" size={12} /> , onClick: () => setShowUserPreferences(!show_user_preferences) },
        { label: "Datos de visualizacion", key: "visualization_data", icon: <Icon icon="filters" size={12} /> , onClick: () => {setShowVisualizationData(!show_vizualization_data)} },
        { type: 'divider' },
        { 
            label: "Cerrar sesion", key: "logout", icon: <Icon icon="logout" size={12} color="#FF4141" /> , onClick: () => {
                const clearStorage = storage.clear() 
                if (clearStorage) {
                    window.location.href = '/logout';
                }
            }
        },
    ];

    const generalTools = [
        { 
            label: <Item label="Link de seguimiento externo" info="Compartir unidades a usuarios externos" />, 
            key: "external_link", 
            icon: <Icon icon="external-link" 
            size={14} />, onClick: () => setShowExternalLink(!show_external_link)
        },
        { 
            label: <Item label="Nueva ruta (Geocerca Lineal)" info="example" />, 
            key: "new-route", 
            icon: <Icon icon="new-route" size={14} />, 
            onClick: () => handleToolActivation("new_route")
        },
        { 
            label: <Item label="Nuevo Punto de referencia" info="Punto de referencia en el mapa" />,
            key: "new-point",
            icon: <FontAwesomeIcon icon={faLocationDot} style={{fontSize: 14}} />,
            onClick: () => handleToolActivation("new_point")
        },
        {
            label: <Item label="Nueva Geocerca (Circular)" info="Geocerca Circular" />,
            key: "new-circular-geofence",
            icon: <Icon icon="geofence" size={14} />,
            onClick: () => handleToolActivation("new_circle_geofence")
        },
        {
            label: <Item label="Nueva Geocerca (Polygonal)" info="Geocerca Polygonal" />,
            key: "new-polygonal-geofence",
            icon: <FontAwesomeIcon icon={faDrawPolygon} style={{fontSize: 14}} />,
            onClick: () => handleToolActivation("new_polygonal_geofence")
        },
        { 
            label: <Item label="Buscar ubicaciones" info="example" />, 
            key: "find-location", 
            icon: <Icon icon="find-location" size={14} />, 
            onClick: () => setSearchPlacesActive(!search_places_active)
        },
        { 
            label: <Item label="Regla (Medir distancias)" 
            info="example" />, 
            key: "size-tool", 
            icon: <Icon icon="size" size={14} />, 
            onClick: () => handleToolActivation("measuring_tool")
        },
        { 
            label: <Item label="Casetas" info="example" />, 
            key: "checkpoint", 
            icon: <Icon icon="checkpoint" size={14} />, 
            onClick: () => {
                window.open(
                    "https://app.sct.gob.mx/sibuac_internet/ControllerUI?action=cmdEscogeRuta", 
                    "CasetasWindow", 
                    "width=800,height=600"
                );
            }
        },
        { 
            label: <Item label="Limpiar mapa" info="example" />, 
            key: "reload", 
            icon: <Icon icon="reload" size={14} />, 
            onClick: () => {
                setSearchPlacesActive(false);
                setMeasuringToolActive(false);
                setNewRouteActive(false);
                setShowNewCircleGeofence(false);
                setShowNewPolygonalGeofence(false);
            }
        },
    ];
    
    
    useEffect(() => {
        setGeofencesNames(geofenceValue.includes('geofences_name'));
        setShowCircularGeofences(geofenceValue.includes('circle'));
        setShowPolygonalGeofences(geofenceValue.includes('polygonal'));
        setShowLinearGeofences(geofenceValue.includes('linear'));
    }, [geofenceValue]);

    useEffect(() => {
        setPoiNames(pointsValue.includes('poi_name'));
    }, [pointsValue]);


    const renderVisualize = ({ onClose, left, top, className, ...rest }, ref) => {
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu>
                <div className="flex-column" style={{paddingRight: "60px"}}>
                    <Checkbox onChange={(value, checked) => setShowDeviceNames(checked)} checked={show_device_names}>Nombres de equipos</Checkbox>
                    <Checkbox onChange={(value, checked) => setShowTraffic(checked)} checked={show_traffic}>Trafico</Checkbox>
                    <CheckboxGroupComponent
                        title="Geocercas"
                        items={geofences}
                        value={geofenceValue}
                        onChange={handleGeofenceChange}
                        onCheckAll={handleGeofenceCheckAll}
                    />
                    <CheckboxGroupComponent
                        title="Puntos de interés"
                        items={points}
                        value={pointsValue}
                        onChange={handlePointsChange}
                        onCheckAll={handlePointsCheckAll}
                    />
                </div>
            </Dropdown.Menu>
          </Popover>
        );
    };

    const renderTools = ({ onClose, left, top, className, ...rest }, ref) => {
        return (
          <Popover ref={ref} className={`${className} tools-popover`} style={{ left, top }} full>
            <Dropdown.Menu>
                {generalTools.map((item) => {
                    return (
                        <Dropdown.Item key={item.key} icon={item.icon} onClick={() => {
                            onClose();
                            item.onClick()
                        }}>
                            {item.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
          </Popover>
        );
    }
    
    // const renderMap = ({ onClose, left, top, className, ...rest }, ref) => {
    //     return (
    //       <Popover ref={ref} className={className} style={{ left, top }} full>
    //         <Dropdown.Menu>
    //             {toolsMapStyles.map(item => (
    //                 <Dropdown.Item key={item.key} onClick={() => setMapStyle(item.key)} className={map_style === item.key ? 'style-active' : ''}>
    //                 {item.label}
    //                 </Dropdown.Item>
    //             ))}
    //         </Dropdown.Menu>
    //       </Popover>
    //     );
    // }
    
    const renderUserMenu = ({ onClose, left, top, className, ...rest }, ref) => {
        const handleSelect = eventKey => {
          onClose();
        };
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu onSelect={handleSelect}>
                {menuItems.map((item, index) => {
                    if (item.type === 'divider') {
                        return <Dropdown.Separator key={item.key || index} />;
                    }
    
                    if (item.type === "link") {
                        return (
                            <Dropdown.Item  key={item.key || index} icon={item.icon} >
                                <a className="link" href={item.href} target="_blank" >{item.label}</a>
                            </Dropdown.Item>
                        );
                    }
                    return (
                        <Dropdown.Item  key={item.key || index} icon={item.icon} onClick={() => {
                            onClose();
                            item.onClick()
                        }} >
                            {item.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
          </Popover>
        );
    }

    return (
        <div className="topbar">
            <div id="topbar-left">
                <figure id="logo">
                    <img src="/assets/images/logo.png" alt="Logo" />
                </figure>
                <InputGroup inside style={{ width: 250, marginLeft: 40 }} className="search-container">
                    <Input className="search-input" placeholder="Buscar equipos" onChange={handleSearch} />
                    <InputGroup.Addon>
                        <Icon icon="icon-find" size={14}  />
                    </InputGroup.Addon>
                </InputGroup>
            </div>
            <div id="tools">
                <div id="toggle-sidebar">
                    <Toggle classPrefix="toggle" onChange={setShowTracking} color="green" defaultChecked={true}>
                        Seguimiento
                    </Toggle>
                </div>
                <div id="tools-buttons">
                    <div ref={ref9}>
                        <Whisper placement="bottomStart" trigger="click" speaker={renderTools}>
                            <Button className="tool-btn">
                                <Icon icon="tools" size={16} />
                            </Button>
                        </Whisper>
                    </div>
                    <div ref={ref10}>
                        <Whisper placement="bottomStart" trigger="click" speaker={renderVisualize}>
                            <Button className="tool-btn">
                                <Icon icon="visualize" size={16} />
                            </Button>
                        </Whisper>
                    </div>

                    {/* <Whisper placement="bottomStart" trigger="click" speaker={renderMap}>
                        <Button className="tool-btn"><Icon icon="maps" size={16} color="white" /></Button>
                    </Whisper> */}
                </div>
            </div>
            <div id="topbar-righ">
                <div className="flex-row align-center">
                    <button id="notification" className={`tool-btn m-0 ${show_notifications ? 'active' : ''}`} onClick={(e) => {
                        setShowNotifications(!show_notifications)
                        e.currentTarget.blur();
                    }}>
                        {unred_notifications > 0 ? 
                            <Badge>
                                <Icon icon="icon-notificaciones" size={17}  />
                            </Badge>
                            :
                            <Icon icon="icon-notificaciones" size={17}  />

                        }
                    </button>
                    <figure id="user-avatar">
                        
                        <Avatar 
                            style={{ width: 26, height: 26, marginTop: 5 }}
                            src={preferences?.user_preferences.profile_picture? APP_URL + preferences?.user_preferences.profile_picture : '/assets/images/avatar.png'}
                        />
                    </figure>
                </div>
                <div ref={ref11}>
                    <Whisper placement="bottomEnd" trigger="click" speaker={renderUserMenu}>
                        <div className="user-info">
                            <span className="user-name">{preferences?.user_preferences.name}</span>
                            <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon"/>
                        </div>
                    </Whisper>
                </div>
            </div>
            <VizualizationModal open={show_vizualization_data} handleOpen={() => setShowVisualizationData(!show_vizualization_data)} />
            <UserPreferencesModal open={show_user_preferences} handleOpen={() => setShowUserPreferences(!show_user_preferences)} />
            {show_external_link && <ExternalLinkModal open={show_external_link} handleOpen={() => setShowExternalLink(!show_external_link)} />}
        </div>
    );
}

export default TopBar;
