import React from 'react';
import { Select } from "antd";
import type { SelectProps } from 'antd';

import { useFilters } from "../../contexts/FilterDevicesContext";
import { useTour } from '../../contexts/TourContext';

const FilterSelects = () => {
    const { groupsAvailables, availableDevicesStatus, handleGroupChange, handleStatusChange } = useFilters();
    const {ref2, ref3} = useTour();

    const sharedProps: SelectProps = {
        mode: 'multiple',
        allowClear: true,
        style: { width: '100%' },
        showSearch: true,
        placeholder: 'Seleccionar',
        maxTagCount: 'responsive',
      };

    return (
        <div className="filter-selects">
            <div id="groups" ref={ref2}>
                <div className="filter-selects-title">Grupos</div>
                <Select
                    {...sharedProps}
                    onChange={(values: string[]) => handleGroupChange(values)}
                >
                    {groupsAvailables.map((group) => (
                        <Select.Option key={group} value={group}>{group}</Select.Option>
                    ))}
                </Select>
            </div>
            <div id="device-status" ref={ref3}>
                <div className="filter-selects-title">Estatus</div>
                <Select
                    {...sharedProps}
                    onChange={(values: string[]) => handleStatusChange(values)}
                >
                    {availableDevicesStatus.map((status) => (
                        <Select.Option key={status} value={status}>{status}</Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    ) 
}

export default FilterSelects;