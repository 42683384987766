// src/components/filter_buttons/FilterButtons.tsx
import React from 'react';
import FilterButton from './FilterButton';
import { useFilters } from "../../contexts/FilterDevicesContext";
import { GpsDeviceStatus } from "../../types/device";
import { useTour } from '../../contexts/TourContext';

const FilterButtons: React.FC = () => {
  const { handleFilterChange, btnselected, statusInfo } = useFilters();
  const { ref1 } = useTour();

  const onSelectStatus = (key: GpsDeviceStatus) => {
    handleFilterChange(key);
  };

  return (
    <div className="filterButton-container" ref={ref1}>
      {statusInfo.map((info, index) => (
        <FilterButton
          key={index}
          info={info}
          selectStatus={() => onSelectStatus(info.key as GpsDeviceStatus)}
          isSelected={btnselected}
        />
      ))}
    </div>
  );
};

export default FilterButtons;
